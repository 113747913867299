import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const DataGridNoRowsOverlay = ({ Message }) => (
  <Stack
    mt={4}
    width="100%"
    height="50%"
    alignItems="center"
    justifyContent="center"
    style={{ zIndex: 5, position: 'relative' }}
  >
    <DescriptionRoundedIcon
      sx={{
        width: 40,
        height: 'auto',
        mb: 1,
        color: 'primary.main',
      }}
    />
    <Box sx={{ maxWidth: 600, textAlign: 'center' }}>
      {Message ? <Message /> : <Typography>Sin filas</Typography>}
    </Box>
  </Stack>
);

DataGridNoRowsOverlay.propTypes = {
  Message: PropTypes.func.isRequired,
};

export default DataGridNoRowsOverlay;
