import React, { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import AttachMoney from '@mui/icons-material/AttachMoney';
import MenuBook from '@mui/icons-material/MenuBook';
import PropTypes from 'prop-types';
import dayjs from '../../../config/dayjs';
import SiiIcon from '../../icons/SiiIcon';

const colors = {
  finished: '#237A39',
  rejected: '#E40F0F',
  blocked: '#F8B147',
  default: '#D4D4D4',
};

const validDictionary = (invoice) => ({
  label: invoice.hasExecutiveMerit
    ? 'Con mérito'
    : `${invoice.daysToExecutiveMerit} días para el mérito ejecutivo`,
  color: invoice.hasExecutiveMerit ? colors.finished : colors.default,
  last: 'sii',
});

const siiStatusMaster = (invoice) => ({
  PROCESSING: { last: 'sii', color: colors.default, label: 'En proceso' },
  REJECTED_BY_SII: {
    last: 'sii',
    color: colors.rejected,
    label: 'Rechazado por SII',
  },
  REJECTED_RECEIVER: {
    last: 'sii',
    color: colors.rejected,
    label: 'Rechazado por Receptor',
  },
  REJECTED_CONTENT: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con reclamos al contenido',
  },
  REJECTED_TOTAL: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con reclamos por falta total',
  },
  REJECTED_PARTIAL: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con reclamos por falta parcial',
  },
  VALID: validDictionary(invoice),
  RECEPTION_ACKNOWLEDGED: {
    last: 'sii',
    color: colors.finished,
    label: 'Con acuse de recibo',
  },
  PARTIAL_CLAIM: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con rechazo por falta parcial de mercaderías',
  },
  TOTAL_CLAIM: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con rechazo por falta total de mercaderías',
  },
  ALREADY_PAYED: {
    last: 'sii',
    color: colors.finished,
    label: 'Emitida al contado',
  },
  INVALID_STATUS: validDictionary(invoice),
  UNKNOWN_STATUS: validDictionary(invoice),
});

const apiMaster = [
  { last: 'sii', color: colors.rejected },
  { last: 'accounting', color: colors.blocked },
  { last: 'payments', color: colors.blocked },
  { last: 'accounting', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'payments', color: colors.finished },
  { last: 'sii', color: colors.default },
];

const SiiCustomIconStepper = ({
  current,
  lastApiState,
  receptionDate,
  siiStatus,
  invoice,
}) => {
  const finishedSteps = {
    sii: [],
    accounting: ['sii'],
    payments: ['sii', 'accounting'],
  };

  const Icon = {
    sii: SiiIcon,
    accounting: MenuBook,
    payments: AttachMoney,
  }[current];
  const iconColor = useMemo(() => {
    if (lastApiState?.rejectionCode) {
      const { last, color } = { last: 'sii', color: colors.rejected };
      if (last === current) return color;
      if (finishedSteps[last].includes(current)) return colors.finished;
      return colors.default;
    }
    if (lastApiState?.status) {
      const { last, color } = apiMaster[lastApiState.status.code - 1];
      if (last === current) return color;
      if (finishedSteps[last].includes(current)) return colors.finished;
      return colors.default;
    }
    const { last, color } = siiStatusMaster(invoice)[siiStatus];
    if (last === current) return color;
    if (finishedSteps[last].includes(current)) return colors.finished;
    return colors.default;
  }, [lastApiState, current, finishedSteps, receptionDate, siiStatus]);
  return (
    <Avatar sx={{ backgroundColor: iconColor, width: 28, height: 28 }}>
      <Icon fontSize="8px" sx={{ color: 'white' }} />
    </Avatar>
  );
};
const siiSteps = ['sii', 'accounting', 'payments'];
export { SiiCustomIconStepper, siiSteps, siiStatusMaster };

SiiCustomIconStepper.propTypes = {
  siiStatus: PropTypes.string.isRequired,
  receptionDate: PropTypes.objectOf(dayjs).isRequired,
  current: PropTypes.string.isRequired,
  lastApiState: PropTypes.shape({
    rejectionCode: PropTypes.shape({
      reason: PropTypes.string,
    }),
    status: PropTypes.shape({
      code: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hasExecutiveMerit: PropTypes.bool.isRequired,
    daysToExecutiveMerit: PropTypes.number.isRequired,
  }).isRequired,
};
