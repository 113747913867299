import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import StatusSummaryStepperPremium from './StatusSummaryStepperPremium';
import dayjs from '../../config/dayjs';

const docTypeDict = {
  33: {},
  34: {},
  101: { color: 'info', label: 'Recibida en SAT', showHoriz: false },
  56: { color: 'success', label: 'Nota de Débito', showHoriz: true },
  61: { color: 'error', label: 'Nota de Crédito', showHoriz: true },
  46: { color: 'info', label: 'Factura de compra', showHoriz: false },
  801: { color: 'info', label: 'Orden de compra', showHoriz: false },
};
export default function StatusSummaryStepper({ invoice, ...props }) {
  if (!invoice) {
    return <></>;
  }
  const { color, label, showHoriz } = docTypeDict[invoice.dteType.code] || {};
  return useMemo(
    () => (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        {...props}
      >
        {['33', '34', '101'].includes(invoice.dteType.code) ? (
          /* For invoices */
          <StatusSummaryStepperPremium invoice={invoice} />
        ) : (
          /* For documents that are not invoices */
          <Stack alignItems="center" direction="row">
            {showHoriz && (
              <SwapHoriz size="small" color={color} sx={{ marginRight: 1 }} />
            )}
            <Typography sx={{ fontSize: '10px' }}>{label}</Typography>
          </Stack>
        )}
      </Box>
    ),
    [invoice],
  );
}

StatusSummaryStepper.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    receptionDate: PropTypes.objectOf(dayjs).isRequired,
    receiver: PropTypes.shape({
      rut: PropTypes.string,
    }),
    dteType: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};
